.breadcrumb-menu {
  position: absolute;
  top: 0;
  right: $breadcrumb-padding-x;

  &::before {
    display: none;
  }

  .btn {
    padding-top: $breadcrumb-padding-y;
    padding-bottom: $breadcrumb-padding-y;
  }

  .btn {
    color: $text-muted;

    &:hover, &.active {
      color: $body-color;
      background: transparent;
    }
  }

  .open {
    .btn {
      color: $body-color;
      background: transparent;
    }
  }

  .dropdown-menu {
    min-width: 180px;
    line-height: $line-height-base;
  }
}
