.breadcrumb {
	padding: $breadcrumb-padding-y $breadcrumb-padding-x;
	margin-bottom: 1rem;
	list-style: none;
	background-color: #3f4954;
	@include border-radius($border-radius);
	@include clearfix;
}

.breadcrumb-item {
	float: left;

	// The separator between breadcrumbs (by default, a forward-slash: "/")
	+ .breadcrumb-item::before {
		display: inline-block; // Suppress underlining of the separator in modern browsers
		padding-right: $breadcrumb-item-padding;
		padding-left: $breadcrumb-item-padding;
		color: $breadcrumb-divider-color;
		content: "#{$breadcrumb-divider}";
	}

	// IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
	// without `<ul>`s. The `::before` pseudo-element generates an element
	// *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
	//
	// To trick IE into suppressing the underline, we give the pseudo-element an
	// underline and then immediately remove it.
	+ .breadcrumb-item:hover::before {
		text-decoration: underline;
	}
	+ .breadcrumb-item:hover::before {
		text-decoration: none;
	}

	&.active {
		color: $breadcrumb-active-color;
	}
}
