.button_clr {
    background-color: #4e73df !important;
    border-color: #4e73df;
    color:#fff
}

.button_clear {
    background-color: #757575 !important;
    border-color: #4e73df;
    color:#fff
}

.button_clear:hover{
    color:#fff
}

.button_clr:hover {
    background-color: #2e59d9 !important;
    border-color: #2e59d9;
    color:#fff
}

.buttonsGroup button{
    margin:5px
}

.tagsList span{
    display:inline-block;
    margin:5px;
    color: #eee;
}

.appointment {
    margin: 10px;
    padding: 10px;
    border-top: 1px solid #eee
}

.radiogroup div{
    align-items: center;

}
.radiogroup div > label{
    align-items: center;
    margin-bottom: 0px;
    margin-left:5px;
}